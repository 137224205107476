<template>
  <div>
    <div class="header-title">{{ $route.meta.title }}</div>
    <div class="">
      <div>
        <div class="btn-con" style="float: left">
          <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="adduser"
          >
            <span class="next-btn-helper">创建用户组</span>
          </button>
        </div>
        <el-input
          v-model="search"
          prefix-icon="el-icon-search"
          size="small"
          style="width: 300px"
          placeholder="输入用户组的名称/显示名"
          clearable
          @keyup.enter.native="get_list"
          @clear="get_list"
        />
        <div
          style="float: right"
          dir="ltr"
          role="gridcell"
          class="next-col next-col-offset-0 sc-ifAKCX ezKSUK action-bar-right"
        >
          <button
            type="button"
            class="next-btn next-medium next-btn-normal isOnlyIcon"
            @click="get_list"
          >
            <span class="next-btn-helper"><i class="el-icon-refresh-right" /></span>
          </button>
        </div>
      </div>
      <Loading :show="loading">
        <div class="list-box">
          <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="headerCallStyle"
            @selection-change="handleCheckedCitiesChange"
          >
            <el-table-column label="用户登录名称/显示名称">
              <template slot-scope="{ row }">
                <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="handle(row)"
                >
                  {{ row.name }}
                </button>
                <p v-if="row.display_name">{{ row.display_name }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="domain_name" label="所属域" />
            <el-table-column prop="remark" label="备注" />
            <el-table-column prop="create_time" label="创建时间" />
            <el-table-column prop="address" label="操作">
              <template slot-scope="{row}">
                <div>
                  <!-- <button
                    type="button"
                    class="next-btn next-medium next-btn-primary next-btn-text"
                    style="margin-right: 8px"
                  >
                    <span class="next-btn-helper">添加组成员</span></button
                    >
                  <button
                    type="button"
                    class="next-btn next-medium next-btn-primary next-btn-text"
                    style="margin-right: 8px"
                  >
                    <span class="next-btn-helper">添加权限</span></button
                  > -->
                  <button
                    type="button"
                    class="next-btn next-medium next-btn-primary next-btn-text"
                    style="margin-right: 8px"
                    @click="delete_main(row)"
                  >
                    <span class="next-btn-helper">删除</span>
                  </button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="padding: 20px 10px 10px; font-size: 18px">
          <el-row>
            <div style="text-align: right">
              <el-pagination
                :page-size="page.page_num"
                :current-page="page.pid"
                background
                layout="total, prev, pager, next"
                :total="total"
                @current-change="handleCurrentChange"
              />
            </div>
          </el-row>
        </div>
      </Loading>
    </div>
  </div>
</template>

<script>
import { group_list, rem_group } from '@/api'

export default {
  data () {
    return {
      page: {
        pid: 1,
        page_num: 10
      },
      ids: [],
      total: 0,
      search: '',
      loading: false,
      tableData: [],
      disabledAll: false,
      checkAll: false,
      isIndeterminate: true
    }
  },
  computed: {
    headerCallStyle () {
      return {
        background: '#eff3f8',
        color: '#333',
        fontWeight: 400,
        fontSize: '12px'
      }
    }
  },
  created () {
    this.get_list()
  },
  methods: {
    get_list () {
      const params = {
        pid: this.page.pid,
        page_num: this.page.page_num,
        search: this.search
      }
      this.loading = true
      group_list(params).then(res => {
        this.loading = false
        this.tableData = res.data.data
        this.total = res.data.all_num
      })
    },
    delete_main (row) {
      const params = {
        group_id: row.id
      }
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        rem_group(params).then(res => {
          this.get_list()
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'success'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleCheckedCitiesChange (list) {
      const checkedCount = list.length
      this.disabledAll = checkedCount
      this.checkAll = checkedCount === this.tableData.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.tableData.length
      this.ids = list.map(({ id }) => id)
    },
    handleCurrentChange (val) {
      this.page.pid = val
      this.get_list()
    },
    adduser () {
      this.$router.push('/groups/add')
    },
    handle ({ id, domain_id }) {
      this.$router.push({
        path: '/groups/handle',
        query: { id, domain_id }
      })
    }
  }
}
</script>

<style>
</style>
